@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@font-face {
  font-family: "apexSansBook";
  src: url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/apex-sans-book/apex-sans-book-regular.eot"); /* IE9 Compat Modes */
  src: url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/apex-sans-book/apex-sans-book-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/apex-sans-book/apex-sans-book-regular.woff") format("woff"), /* Modern Browsers */
       url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/apex-sans-book/apex-sans-book-regular.ttf")  format("truetype"); /* Safari, Android, iOS */
  font-display: swap;
}

@font-face {
  font-family: "bebas";
  src:url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/babas/3d9ec9a9-76a2-4cfe-b376-76e7340c3b50.eot?#iefix");
  src:url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/babas/3d9ec9a9-76a2-4cfe-b376-76e7340c3b50.eot?#iefix") format("eot"),
    url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/babas/e0d6f852-5401-4bbf-9672-47a50c5c87c6.woff2") format("woff2"),
    url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/babas/7fedd582-6ae3-4850-be2f-4acae2e74fa5.woff") format("woff"),
    url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/babas/d6e08ef3-40db-4ac3-82df-f062f55a72f5.ttf") format("truetype"),
      url("https://www.teamlewis.com/wp-content/themes/Lewis/assets/fonts/babas/849e0009-7435-46a3-b88d-c41c3ff639d4.svg#849e0009-7435-46a3-b88d-c41c3ff639d4") format("svg");
  font-display: swap;
}


@layer base {

  h1,
  .h1 {
    @apply text-2xl font-headline leading-none;
  }

  h2,
  .h2 {
    @apply text-xl font-medium leading-none;
  }

  h3,
  .h3 {
    @apply text-lg font-medium leading-none;
  }
}